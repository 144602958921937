import {
  accountInfoUrl,
  changePasswordUrl,
  customModelsUrl,
  digitalShowcaseUrl,
  discountsUrl,
  helpCenterUrl,
  incompleteOrdersUrl,
  invoicesUrl,
  logoutUrl,
  measurementsGuideUrl,
  optionModelVisibilityUrl,
  orderPartsUrl,
  paymentHistoryUrl,
  policiesUrl,
  returnProgramUrl,
  searchOrdersUrl,
  shippingHoldUrl,
  studioUrl,
  switchToAgentUrl,
  switchToTrinityUrl,
  universityUrl,
} from '@trinity/utils'
import type { NavList } from './types'

export const navLinks: NavList[] = [
  {
    title: 'Orders',
    menu: [
      {
        title: 'Place New Order',
        link: 'new-order',
        permissions: 'can_place_order',
        reactPage: true,
      },
      {
        title: 'Order Parts',
        link: orderPartsUrl,
        permissions: 'can_order_parts',
        reactPage: false,
      },
      {
        title: 'Order Search',
        link: searchOrdersUrl,
        permissions: 'can_search_orders',
        reactPage: false,
      },
      {
        title: 'Saved Orders',
        link: incompleteOrdersUrl,
        permissions: 'can_view_saved_orders',
        reactPage: false,
      },
    ],
  },
  {
    title: 'Clients',
    menu: [
      {
        title: 'View Clients',
        link: 'clients',
        permissions: 'can_view_clients',
        reactPage: true,
      },
      {
        title: 'Client Insights',
        link: 'clients/insights',
        permissions: 'can_view_client_insights',
        reactPage: true,
      },
    ],
  },
  {
    title: 'Fabrics',
    menu: [
      {
        title: 'Fabric Explorer',
        link: 'fabric-explorer/collections',
        permissions: 'can_explore_collections',
        reactPage: true,
      },
      {
        title: 'Price List',
        link: 'price-list',
        permissions: 'can_view_price_list',
        reactPage: true,
      },
    ],
  },
  {
    title: 'University',
    menu: [
      {
        title: 'University',
        link: universityUrl,
        permissions: 'can_view_university',
        reactPage: false,
      },
      {
        title: 'Option Library',
        link: 'library/options',
        permissions: 'can_view_library',
        reactPage: true,
      },
      {
        title: 'Model Library',
        link: 'library/models',
        permissions: 'can_view_library',
        reactPage: true,
      },
      {
        title: 'Material Package',
        link: 'library/materials',
        permissions: 'can_view_library',
        reactPage: true,
      },
    ],
  },
  {
    title: 'Support',
    menu: [
      {
        title: ' Trinity Help Center',
        link: helpCenterUrl,
        permissions: 'can_view_help_center',
        reactPage: false,
      },
      {
        title: 'Measurements Guide',
        link: measurementsGuideUrl,
        permissions: 'can_view_measurements_guide',
        reactPage: false,
      },
      {
        title: '40/40 Return Program',
        link: returnProgramUrl,
        permissions: 'can_view_40_40_program',
        reactPage: false,
      },
      {
        title: 'Policies',
        link: policiesUrl,
        permissions: 'can_view_policies',
        reactPage: false,
      },
      { divider: true, title: 'divider1' },
      { title: 'Contact Us', link: `#`, permissions: 'can_contact_us', reactPage: false },
    ],
  },
  {
    title: 'Marketing',
    permissions: 'can_view_marketing',
    menu: [
      {
        title: 'Studio',
        link: studioUrl,
        permissions: 'can_view_studio',
        reactPage: false,
      },
      {
        title: 'Digital Showcase',
        link: digitalShowcaseUrl,
        permissions: 'can_view_digital_showcase',
        reactPage: false,
      },
    ],
  },
  {
    title: 'My Account',
    menu: [
      {
        title: 'Dashboard',
        link: 'dealer-home',
        permissions: 'can_view_dashboard',
        reactPage: true,
      },
      {
        title: 'Account Info',
        link: accountInfoUrl,
        permissions: 'can_view_account_info',
        reactPage: false,
      },
      {
        title: 'Custom Models',
        link: customModelsUrl,
        permissions: 'can_view_custom_models',
        reactPage: false,
      },
      {
        title: 'Option & Model Visibility',
        link: optionModelVisibilityUrl,
        permissions: 'can_view_option_model_visibility',
        reactPage: false,
      },
      {
        title: 'Shipping Hold',
        link: shippingHoldUrl,
        permissions: 'can_view_dashboard',
        reactPage: false,
      },
      {
        title: 'Change Password',
        link: changePasswordUrl,
        permissions: 'can_change_password',
        reactPage: false,
      },
      { divider: true, title: 'divider-1' },
      {
        title: 'Trend Report',
        link: 'reports/rank',
        permissions: 'can_view_trend_reports',
        reactPage: true,
      },
      {
        title: 'Discounts',
        link: discountsUrl,
        permissions: 'can_view_discounts',
        reactPage: false,
      },
      {
        title: 'Manage Credit Cards',
        link: 'payments/credit-cards',
        permissions: 'can_manage_credit_cards',
        reactPage: true,
      },
      {
        title: 'Payment History',
        link: paymentHistoryUrl,
        permissions: 'can_view_payment_history',
        reactPage: false,
      },
      {
        title: 'Open Balances',
        link: 'payments/manage?type=balances',
        permissions: 'can_view_balances',
        reactPage: true,
      },
      {
        title: 'Order Payment Errors',
        link: 'payments/manage?type=errors',
        permissions: 'can_view_payment_errors',
        reactPage: true,
      },
      {
        title: 'Invoices',
        link: invoicesUrl,
        permissions: 'can_view_invoices',
        reactPage: false,
      },
      { divider: true, title: 'divider-2' },
      {
        title: 'Switch to Trinity',
        link: switchToTrinityUrl,
        permissions: 'can_switch_to_trinity',
        reactPage: false,
      },
      {
        title: 'Switch to Agent',
        link: switchToAgentUrl,
        permissions: 'can_switch_to_agent',
        reactPage: false,
      },
      {
        title: 'Log Out',
        link: logoutUrl,
        permissions: 'can_log_out',
        reactPage: false,
      },
    ],
  },
]
